/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { _ as r } from "../../chunks/tslib.es6.js";
import { ClonableMixin as o } from "../../core/Clonable.js";
import { JSONSupport as e } from "../../core/JSONSupport.js";
import { property as t } from "../../core/accessorSupport/decorators/property.js";
import "../../core/has.js";
import "../../core/Logger.js";
import "../../core/RandomLCG.js";
import { subclass as s } from "../../core/accessorSupport/decorators/subclass.js";
let p = class extends o(e) {
  constructor(r) {
    super(r), this.onFields = null, this.operator = null, this.searchTerm = null, this.searchType = null;
  }
};
r([t({
  type: [String],
  json: {
    write: {
      enabled: !0,
      overridePolicy() {
        return {
          enabled: null != this.onFields && this.onFields.length > 0
        };
      }
    }
  }
})], p.prototype, "onFields", void 0), r([t({
  type: String,
  json: {
    write: !0
  }
})], p.prototype, "operator", void 0), r([t({
  type: String,
  json: {
    write: !0
  }
})], p.prototype, "searchTerm", void 0), r([t({
  type: String,
  json: {
    write: !0
  }
})], p.prototype, "searchType", void 0), p = r([s("esri.rest.support.FullTextSearch")], p);
const i = p;
export { i as default };